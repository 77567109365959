import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { SolarClockProvider } from './providers/solar-clock-provider';
import { ModalProvider } from './providers/modal-provider';
import { InfoPanelProvider } from './components/info-panel';
import AuthProvider from './providers/auth-provider';
import { UvProvider } from './providers/uv-provider';
import { PageProvider } from './providers/page-provider';
import ErrorBoundary from './components/error-boundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <AuthProvider>
      <InfoPanelProvider>
        <ModalProvider>
          <PageProvider>
            <SolarClockProvider>
              <UvProvider>
                <App />
              </UvProvider>
            </SolarClockProvider>
          </PageProvider>
        </ModalProvider>
      </InfoPanelProvider>
    </AuthProvider>
  </ErrorBoundary>,
);

serviceWorkerRegistration.register();
