import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  DefaultLocationData,
  fetchLocationData,
  getCoordinates,
  getGeolocation,
  getLocationFromCoords,
  getTimeZone,
  isDefaultCoordinates,
  fetchElevation,
} from '../utils/geo';
import { adjustDay, formatDayTimeAndZone, isDateSame } from '../utils/time';
import { Days, useCurrentTime } from '../hooks/use-current-time';
import {
  getSunTimes,
  useSunTimes,
  useSunPosition,
  useMoonPosition,
} from '../hooks/use-sun-times';
import { useMoonPhase } from '../hooks/use-moon-phase';
import { CircadianEventType } from '../components/circadian_event';
import logger from '../utils/logger';
import {
  loadDataFromCache,
  saveDataToCache,
  saveLocationDataToCache,
} from '../utils/local-storage';

// Create the context for the solar clock
const SolarClockContext = createContext();

export const useSolarClock = () => {
  // Custom hook to access context values
  const context = useContext(SolarClockContext);
  if (!context) {
    throw new Error('useSolarClock must be used within a SolarClockProvider');
  }
  return context;
};

export const SolarClockProvider = ({ children }) => {
  const [isClockDataLoaded, setIsClockDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(DefaultLocationData.coordinates);
  const [location, setLocation] = useState(DefaultLocationData.location);
  const [timezone, setTimezone] = useState(DefaultLocationData.timezone);
  const [elevation, setElevation] = useState(DefaultLocationData.elevation);
  const [displayDate, setDisplayDate] = useState(Days.Today(timezone));
  const [activeEvent, setActiveEvent] = useState();
  const [detectLocationFailed, setDetectLocationFailed] = useState(false);
  const { currentTime, startClock, stopClock, syncCurrentTime, isClockStopped } =
    useCurrentTime(timezone);

  function employDefaultLocation() {
    logger.info('Employing default location');
    initLocationData(
      DefaultLocationData.location,
      DefaultLocationData.coordinates,
      DefaultLocationData.timezone,
      DefaultLocationData.elevation,
    );
    setIsClockDataLoaded(true);
  }

  // Use Effect for obtaining coordinates and updating location
  useEffect(() => {
    setIsLoading(true);
    fetchLocationData()
      .then(({ location, coordinates: coords, timezone: tz, elevation }) => {
        setIsLoading(false);
        saveLocation(location, coords, tz, elevation);
        setIsClockDataLoaded(true);
      })
      .catch((e) => {
        setIsLoading(false);
        logger.warn('Error detecting location', e.message);
        setDetectLocationFailed(true);
        employDefaultLocation();
      });
  }, []);

  const sunTimes = useSunTimes(coordinates, displayDate, timezone);
  const sunPosition = useSunPosition(coordinates, displayDate);
  const moonPhase = useMoonPhase(coordinates, displayDate);
  const moonPosition = useMoonPosition(coordinates, displayDate);

  useEffect(() => {
    if (!isClockStopped && isDateSame(currentTime, Days.Today(timezone))) {
      // logger.('Updating display date to current time');
      setDisplayDate(currentTime);
    }
  }, [currentTime, timezone]);

  useEffect(() => {
    syncCurrentTime();
  }, [coordinates.latitude, coordinates.longitude, location, timezone]);

  const isDateToday = useCallback(() => {
    return isDateSame(displayDate, Days.Today(timezone));
  }, [displayDate, timezone]);

  const isDateTomorrow = useCallback(() => {
    return isDateSame(displayDate, Days.Tomorrow(timezone));
  }, [displayDate, timezone]);

  function initLocationData(location, coordinates, timezone, elevation) {
    setLocation(location.displayName);
    setCoordinates(coordinates);
    setTimezone(timezone);
    setElevation(elevation);
  }

  const handleDisplayDateChange = (newDate) => {
    setDisplayDate(newDate);
  };

  const findLocation = async (location) => {
    setIsLoading(true);
    const coordinates = await getCoordinates(location);
    let timezone;
    if (!isDefaultCoordinates(coordinates)) {
      timezone = await getTimeZone(coordinates.latitude, coordinates.longitude);
    } else {
      timezone = 'America/Los_Angeles';
    }
    setIsLoading(false);
    return { location, coordinates, timezone };
  };

  function saveLocation(location, coordinates, timezone, elevation) {
    initLocationData(location, coordinates, timezone, elevation);
    const locationData = { location: location, coordinates, timezone, elevation };
    void saveLocationDataToCache(locationData);
  }

  const selectLocation = async (location) => {
    // logger.('Selected Location', location);
    setIsLoading(true);
    const coordinates = { latitude: location.lat, longitude: location.lon };
    let timezone;
    let elevation;
    if (!isDefaultCoordinates(coordinates)) {
      [timezone, elevation] = await Promise.all([
        getTimeZone(coordinates.latitude, coordinates.longitude),
        fetchElevation(coordinates.latitude, coordinates.longitude),
      ]);
    } else {
      timezone = 'America/New_York';
      elevation = 0;
    }
    setIsLoading(false);
    saveLocation(location, coordinates, timezone, elevation);
    setDetectLocationFailed(false);
  };

  const isNight = (sunTime) => {
    const { sunrise, sunset } = sunTimes;
    return sunTime < sunrise || sunTime > sunset;
  };

  function activateNullEvent(eventDate) {
    // logger.('\n\n\n');
    const eventTime = eventDate || displayDate;
    if (isDateSame(eventTime, Days.Today(timezone))) {
      // logger.('activating null event for today');
      setDisplayDate(currentTime);
      setActiveEvent();
      startClock();
    } else {
      // logger.('activating null event for another day');
      setDisplayDate(sunTimes.sunrise);
      setActiveEvent(CircadianEventType.Sunrise);
    }
  }

  function getEventTime(eventLabel, eventDate) {
    const [eventKey] = Object.entries(CircadianEventType).find(
      ([key, value]) => eventLabel === value,
    );
    // logger.('eventKey', eventKey);

    const camelCaseKey = eventKey.charAt(0).toLowerCase() + eventKey.slice(1);
    const adjustedSunTimes = eventDate
      ? getSunTimes(coordinates, eventDate, timezone)
      : sunTimes;
    return adjustedSunTimes[camelCaseKey];
  }

  const activateEvent = (eventLabel, eventDate) => {
    if (eventLabel) {
      let activeLabel = eventLabel;
      let eventTime = getEventTime(eventLabel, eventDate);
      if (eventTime.invalid) {
        activeLabel = CircadianEventType.SolarNoon;
        eventTime = getEventTime(activeLabel, eventDate);
      }
      const adjustedEventDate = eventDate ? adjustDay(eventDate, eventTime) : eventTime;

      if (adjustedEventDate) {
        setActiveEvent(activeLabel);
        setDisplayDate(adjustedEventDate);
        stopClock();
        return;
      }
    }
    activateNullEvent(eventDate);
  };

  const clearEvent = (eventDate) => {
    activateNullEvent(eventDate);
  };

  // Provide context to children components
  return (
    <SolarClockContext.Provider
      value={{
        coordinates,
        location,
        timezone,
        elevation,
        currentTime,
        displayDate,
        today: Days.Today(timezone),
        tomorrow: Days.Tomorrow(timezone),
        sunTimes,
        sunPosition,
        moonPhase,
        moonPosition,
        isDateToday,
        isDateTomorrow,
        handleDisplayDateChange,
        isLocationLoading: isLoading,
        findLocation,
        selectLocation,
        isNight,
        activeEvent,
        activateEvent,
        clearEvent,
        isClockDataLoaded,
        detectLocationFailed,
      }}
    >
      {children}
    </SolarClockContext.Provider>
  );
};
